import { jsx } from 'theme-ui';
import { myTheme } from "../../../../src/components/mdx-deck/customTheme";
import ListSteps from "../../../../src/components/mdx-deck/ListSteps";
import { CSTheme } from "../../../../src/components/mdx-deck/custom-codesurfer-theme";
import { CodeSurfer, Step } from "code-surfer";
import CodeSurferColumns from "../../../../src/components/mdx-deck/CodeSurferColumns";
import Content from "../../../../src/components/mdx-deck/Content";
import TightParagraphs from "../../../../src/components/mdx-deck/TightParagraphs";
import "prismjs/components/prism-java";
import * as React from 'react';
export default {
  jsx,
  myTheme,
  ListSteps,
  CSTheme,
  CodeSurfer,
  Step,
  CodeSurferColumns,
  Content,
  TightParagraphs,
  React
};