import { jsx } from 'theme-ui';
import { myTheme } from "../../../../src/components/mdx-deck/customTheme";
import ListSteps from "../../../../src/components/mdx-deck/ListSteps";
import { CSTheme } from "../../../../src/components/mdx-deck/custom-codesurfer-theme";
import { CodeSurfer, Step } from "code-surfer";
import CodeSurferColumns from "../../../../src/components/mdx-deck/CodeSurferColumns";
import Content from "../../../../src/components/mdx-deck/Content";
import TightParagraphs from "../../../../src/components/mdx-deck/TightParagraphs";
import "prismjs/components/prism-java";
import img from "../../../../decks/0_Basics/supertrees_diag1.png";
import img6 from "../../../../decks/0_Basics/supertrees_diag6.png";
import img2 from "../../../../decks/0_Basics/supertrees_diag2.png";
import img5 from "../../../../decks/0_Basics/supertrees_diag5.png";
import img3 from "../../../../decks/0_Basics/supertrees_diag3.png";
import img4 from "../../../../decks/0_Basics/supertrees_diag4.png";
import img7 from "../../../../decks/0_Basics/supertrees_diag7.png";
import * as React from 'react';
export default {
  jsx,
  myTheme,
  ListSteps,
  CSTheme,
  CodeSurfer,
  Step,
  CodeSurferColumns,
  Content,
  TightParagraphs,
  img,
  img6,
  img2,
  img5,
  img3,
  img4,
  img7,
  React
};